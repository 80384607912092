@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */

:root {

  --alternate-color: #6bc532;
  --main-color: #4c4c4b;

}

.blank-container {

  background-image: url(/assets/images/background/login-register.jpg);

  .mat-card {

    background: var(--main-color);
    color: white;

    a, label {

      color: white !important;

    }

  }

}

.navbar-brand {

  img {

    height: 40px;

  }

}

// http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5

@import '@angular/material/theming';

$secondary-palette: (
  50 : #edf8e6,
  100 : #d3eec2,
  200 : #b5e299,
  300 : #97d670,
  400 : #81ce51,
  500 : #6bc532,
  600 : #63bf2d,
  700 : #58b826,
  800 : #4eb01f,
  900 : #3ca313,
  A100 : #e0ffd5,
  A200 : #b9ffa2,
  A400 : #92ff6f,
  A700 : #7fff56,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$primary-palette: (
  50 : #eaeae9,
  100 : #c9c9c9,
  200 : #a6a6a5,
  300 : #828281,
  400 : #676766,
  500 : #4c4c4b,
  600 : #454544,
  700 : #3c3c3b,
  800 : #333333,
  900 : #242423,
  A100 : #f18080,
  A200 : #ec5252,
  A400 : #ff0c0c,
  A700 : #f10000,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$primary: mat.define-palette($primary-palette, 600);
$accent: mat.define-palette($secondary-palette, 500);
$warn: mat.define-palette(mat.$red-palette, 500);
$theme: mat.define-light-theme($primary, $accent, $warn);

@include mat.all-legacy-component-themes($theme);

.ct-model-submit-button {

  background-color: var(--alternate-color) !important;
  color: white !important;

}
