/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@use '@angular/material' as mat;

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

@import 'variable';

@import 'icons/themify-icons/themify-icons.css';
@import 'icons/font-awesome/css/font-awesome.css';
@import 'icons/flag-icon-css/flag-icon.min.css';

@import 'spinner';
@import 'app';
@import 'header';

@import 'horizontal.scss';

@import 'pages';
@import 'sidebar';
@import 'dark-sidebar';
@import 'responsive';
@import 'rtl';

// Dark Theme

.dark {
  .mat-drawer-container {
    color: #8999a0 !important;
  }

  @import 'colors/dark';
  @include mat.all-legacy-component-themes($dark-theme);
}

$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Poppins',
);
// TODO(v15): As of v15 mat.legacy-_core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

.blank-container {

  height: 100vh;
  display: flex;
  background-size: cover;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgb(1 0 61 / 14%);
  background-position: center center;

  .mat-card {

    margin: 0px 15px;

  }

  .mat-form-field {

    width: 100%;

  }

  .blank-container-box {
    max-width: 400px;
    min-width: 280px;
    margin: auto;
    vertical-align: middle;

    .password-cntr {

      .mat-form-field-infix {

        display: flex !important;

      }

      .material-icons {

        vertical-align: text-bottom;

      }

    }

  }

  .support-text {
    margin-top: -15px;
    display: block;
    padding-bottom: 15px;
  }
}

.mat-raised-button.mat-button-disabled {

  color: white !important;
  background-color: rgba(0, 0, 0, 0.5);

}

// CUSTOM STYLING

.form-control-cntr {

  .mat-checkbox {

    .mat-checkbox-inner-container {

      height: 24px;
      width: 24px;

    }

  }

}


body {

  font-weight: 200 !important;

}

ct-search-filters {

  .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {

    font-size: 22px;
    color: var(--alternate-color);

  }

  .filters-cntr {

    .mat-expansion-panel-header, .mat-expansion-panel-body, .mat-action-row {

      padding: 30px 50px;

    }

    .mat-action-row {

      border-top: none;
      justify-content: end !important;

    }

  }

}

.sidebar-toggle {

  .mat-icon {

    height: 32px;
    width: 32px;
    line-height: 32px !important;
    font-size: 32px;
    font-weight: bold;

  }

  margin-right: 50px !important;

}

.user-profile-cntr {

  color: white;
  padding-top: 5px;
  font-weight: lighter;
  margin-right: 25px;

}

.mat-toolbar {

  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.5) !important;

}


.app-sidebar {

  .separator {

    color: var(--alternate-color) !important;
    font-size: 18px !important;

  }

  .mat-list-item {

    .mat-list-item-content {

      a {

        border-radius: 0px !important;
        color: var(--main-color) !important;

        .mat-icon {

          color: var(--alternate-color) !important;

        }

      }

      a[appaccordiontoggle] {

        display: flex !important;

      }

    }

    &.selected {

      .mat-list-item-content {

        a[appaccordiontoggle] {

          color: var(--alternate-color) !important;

        }

      }

    }

  }

}

.minisidebar {

  .separator {

    span {

      font-size: 12px !important;
    }

  }

}
.note-dialog-container{
  .mat-dialog-container {

    min-width: 500px;
    min-height: 260px;
    .mat-dialog-actions {

      display: flex;
      justify-content: space-between;
  
    }
  


  }
}

.item-selection-import-cntr, .module-add-item-container, .module-edit-item-container, .item-supplier-form-container, .user-working-hours-wizard-container, .work-order-file-upload-container, .lead-file-upload-container {

  .mat-dialog-container {

    padding: 0px;

    .mat-card {

      margin: 0px;

    }

  }

}

.user-working-hours-wizard-container, .work-order-file-upload-container, .lead-file-upload-container {

  .mat-card-header-text {

    width: 100%;

  }

  .control-cntr {

    padding-bottom: 0px;

  }


  .mat-card-content {

    padding: 5px 0px;

  }

  .mat-dialog-content {

    margin: 0px !important;
    padding: 0px !important;
    max-height: 95vh !important;

  }

  .mat-card-actions {

    padding: 0px 5px !important;

    button {

      margin-bottom: 5px;

    }

  }

}

// serve a mostrare lo spinner nelle tabelle, non capisco come mai da pacchetto non viene ereditata questa classe. Per il momento lo manteniamo cosi
.progress-linear {
  position: relative !important;

  .container {
    max-width: 100% !important;
  }
}


.w-100 {

  width: 100%;

}

.control-cntr {

  padding: 10px;

}


.ct-model-on-fly-create-container, .user-working-hours-pictures-container {

  .mat-dialog-container {

    .datatable-body {
      width: 100% !important;
    }

    margin: auto;
    padding: 0px !important;

  }

  .mat-card {

    margin: 0px !important;

  }

}

.ct-manager-master-detail-container {

  padding: 0px 15px;

  .mat-card:first-child {

    margin: 0px;

  }

}

.ct-enumerable {

  &.Completato, &.Attivo {
    color: #23a136;
  }

  &.Fallito {
    color: #ff2c28;

  }

  &.Nuovo, &.Bozza {
    color: rgb(82, 186, 255);
  }

  &.In.gestione, &.In.esecuzione {
    color: rgb(255, 179, 39);
  }

  &.Cancellato, &.Chiuso {
    color: black;
  }

  &.Replaced, &.Sostituito {

    color: color(srgb 0.2445 0.4647 0.6957)

  }

}

.p-datatable {

  font-size: 12px;

  input {

    font-size: 12px;

  }

  tbody {

    .p-element{

      font-size: 14px !important;
      font-weight: 400;
      font-family: poppins, sans-serif;

    }

    td {

      vertical-align: initial !important;

    }

    .p-button-text {

      &.primary {

        color: var(--alternate-color);
        background-color: transparent;

      }

      &.warn {

        color: var(--main-color);
        background-color: transparent;

      }

    }

    .p-button-text {

      &.primary:hover {

        background-color: transparent !important;
        color: var(--alternate-color) !important;

      }

      &.warn:hover {

        background-color: transparent !important;
        color: var(--main-color) !important;

      }

    }

  }

  .p-datatable-tbody {

    tr:nth-of-type(even) {

      background: rgba(240, 244, 247, 0.71); // Opacità al 50%

    }

    tr:nth-of-type(odd) {

      background: #ffffff; // Opacità al 70%

    }

  }

  .p-datatable-thead > tr > th {

    background: transparent;
    color: rgba(119, 119, 119, 0.87);
    padding: 0.5rem;

  }

  .p-datatable-tbody > tr > td {

    padding: 0.5rem 0.5rem;

  }

  .p-paginator {

    font-size: 12px;

    button {

      font-size: 12px;

    }

    .p-paginator-page {

      min-width: 1.5rem;
      height: 1.5rem;
      border-radius: 5px;

      &.p-highlight {

        font-weight: bold;
        background-color: rgba(158, 158, 158, 0.2);
        color: black;

      }

    }

  }

}

.mat-snack-bar-container {

  background: var(--alternate-color) !important;
  font-weight: 400;

  .mat-simple-snackbar-action {

    color: var(--main-color) !important;

  }

}
