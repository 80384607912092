/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables

$bodyfont: 'Poppins', sans-serif;
$headingfont: 'Poppins', sans-serif;

@use '@angular/material' as mat;
@import '@angular/material/theming';

$themecolor: var(--main-color);
$themecolor-alt: var(--alternate-color);

/*Material Theme Colors*/

// Palette generator http://mcg.mbitson.com/#!?mcgpalette

$primary-palette: (
  50 : #e0edf2,
  100 : #b3d1df,
  200 : #80b2ca,
  300 : #4d93b5,
  400 : #267ca5,
  500 : #006595,
  600 : #005d8d,
  700 : #005382,
  800 : #004978,
  900 : #003767,
  A100 : #96c7ff,
  A200 : #63abff,
  A400 : #308fff,
  A700 : #1782ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$secondary-palette: (
  50 : #f0f7e1,
  100 : #d9ebb3,
  200 : #bfde81,
  300 : #a5d04f,
  400 : #92c629,
  500 : #7fbc03,
  600 : #77b603,
  700 : #6cad02,
  800 : #62a502,
  900 : #4f9701,
  A100 : #deffc2,
  A200 : #c2ff8f,
  A400 : #a6ff5c,
  A700 : #98ff42,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$primary: mat.define-palette($primary-palette, 600);
$accent: mat.define-palette($secondary-palette, 500);
$warn: mat.define-palette(mat.$red-palette, 500);
$theme: mat.define-light-theme($primary, $accent, $warn);

// Default Theme
@include mat.all-legacy-component-themes($theme);

// Dark Theme

/*Theme Colors*/

$sidebar: #fff;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;

$font-16: 16px;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #00ccfd;
$warning: #ffb22b;
$primary: #7460ee;
$info: #0087fd;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;

/*Light colors*/
$light-danger: #f9e7eb;

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 4px;

$page-wrapper-boxed-width: 95vw;
$page-wrapper-boxed-mobile-width: 100%;
